









































import {Component, Prop, Vue} from 'vue-property-decorator';
import {RepositoryFactory} from "@/api/RepositoryFactory";
import {MachinePdf} from "@/models/machinePdf";
import DocumentPdfRepository from "@/api/DocumentPdfRepository";

const pdfRepository: DocumentPdfRepository = RepositoryFactory.get('pdf');

@Component({})
export default class PdfCardComponent extends Vue {

  @Prop({default: () => []})
  public pdfs!: MachinePdf[];

  public showFullscreen = false;
  public selectedPdf: MachinePdf | null = null;

  public showImageFullscreen(pdf: MachinePdf) {
    this.selectedPdf = pdf;
    this.showFullscreen = true;
  }

  public getPdfString(pdf: MachinePdf): string {
    if (pdf.pdf.startsWith("data")) {
      return `${pdf.pdf}`
    } else return `data:image/png;base64,${pdf.pdf}`
  }

  public async deletePdf(pdf: MachinePdf) {
    try {
      const response = await pdfRepository.deletePdf(pdf);
      if (response.data.id) {
        this.$emit('removedPdf', pdf);
      }
    } catch (e) {
      // TODO: Show error
    }
  }

}
